/* eslint-disable no-control-regex */
/* eslint-disable no-unused-vars */

import event from "./event";
import replies from "./replies";
import { useMainStore } from "@/mainStore";
import { checkNameSameWithModerator, showNotification } from "@/helpers";
import { join, send } from "./client";

let roomList = [];
let store = null;

event.on(replies.RPL_WELCOME, () => { });

event.on(replies.RPL_ENDOFMOTD, () => {
  store = useMainStore();
  store.setLoginModal(false);
  store.hideRegisterModal();
  send(`LIST`);
});


event.on(replies.RPL_PING, () => {
  send("PONG :www.ABiGChat.com");
});

event.on(replies.RPL_PASSWORDNEEDED, () => {
  store = useMainStore();
  store.hideRoomList();
  store.setIsLoggedIn(false);
  store.setPasswordModal(true);
});

event.on(replies.RPL_INVALIDPASSWORD, () => {
  store.setPasswordError("Invalid password, please try again");
});

event.on(replies.RPL_LOGGEDIN, () => {
  store.setIsLoggedIn(true);
  store.setPasswordModal(false);
  send(`LIST`);
});

event.on(replies.RPL_LISTSTART, () => {
  roomList = [];
});

event.on(replies.ERR_NICKNAMEINUSE, (data) => {
  const store = useMainStore()
  const splitMessage = data.split(":")
  let message = {
    message: splitMessage[2],
    type: 'warning',
  }
  store.storeActionMessage(message)
  store.showActionMessage();
  setTimeout(() => {
    store.hideActionMessage();
    store.enableButton()
  }, 3000);
})

event.on(replies.RPL_YOURID, (data) => {
  console.log(data);
})

event.on(replies.RPL_LIST, (rooms) => {
  const messageParts = rooms.split(" ");
  let topic = messageParts.slice(5).join(" ");
  topic = topic.replace(/^:/, "");

  roomList.push({
    name: messageParts[3],
    count: messageParts[4],
    topic: topic,
  });
});

event.on(replies.RPL_LISTEND, () => {
  store.setRooms(roomList);
  if (store.isLoggedIn) {
    store.showRoomList();
  }
});

event.on(replies.RPL_QUIT, (data) => {
  const store = useMainStore();
  let userName = data.split("!")
  let name = userName[0].replace(/^:/, "")

  let quitUserDetail = {
    type: 'quit',
    userName: name,
    roomName: store.activeRoom,
    message: "has left the server.",
  }
  store.removeUserFromAllRooms(name);
  // store.removeRoomUser(name, store.activeRoom);
  store.addQuitUserMessage(quitUserDetail);
});

event.on(replies.RPL_ENDOFNAMES, (data) => {
  console.log("JOINED room", data);
});

event.on(replies.RPL_NAMREPLY, (data) => {
  const store = useMainStore();
  const room = data.split(" ")[4]

  let firstColonIndex = data.indexOf(':');
  let secondColonIndex = data.indexOf(':', firstColonIndex + 1);
  let substringAfterSecondColon = data.substring(secondColonIndex + 1);
  // Split the substring by space
  let nameArray = substringAfterSecondColon.trim().split(' ');
  nameArray.forEach(singUser => {
    let userDetail = {
      userName: singUser,
      room: room,
      gender: '',
      dob: '',
      type: '',
      country: '',
      profilePhoto: '',
    }

    // Name update detect once mode changes
    const matchedUser = store.socketUserList.find(user => user.user === userDetail.userName || ('@' + user.user) === userDetail.userName);

    if (matchedUser) {
      userDetail.gender = matchedUser.gender;
      userDetail.type = matchedUser.type;
      userDetail.country = matchedUser.country;
      userDetail.profilePhoto = matchedUser.profilePhoto;
    } else {
      userDetail.gender = 'others'
      userDetail.type = 'unknown';
      userDetail.country = 'Earth';
    }

    let today = new Date();
    if (matchedUser) {
      const dob = new Date(matchedUser.dob);
      const age = today.getFullYear() - dob.getFullYear();
      userDetail.dob = age;
    } else {
      userDetail.dob = '18'
    }
    store.addUser(userDetail);
    store.storeNewUser(userDetail)
  });
})

event.on(replies.RPL_JOIN, (data) => {
  const store = useMainStore();

  // Split the text into parts to extract the necessary information
  const parts = data.split(" ");
  const usernameAndHost = parts[0].substring(1); // Removes the leading ":"
  const room = parts[2].substring(1); // Removes the leading ":"
  const [name, host] = usernameAndHost.split("!");

  let roomUser = {
    userName: name,
    room: room,
    gender: 'gender',
    dob: '',
    country: '',
    type: 'guest',
  }

  const matchedUser = store.socketUserList.find(user => user.user === name);
  if (matchedUser) {
    roomUser.gender = matchedUser.gender;
    roomUser.type = matchedUser.type;
    roomUser.country = matchedUser.country;
    roomUser.profilePhoto = matchedUser.profilePhoto;
  } else {
    roomUser.gender = 'others'
    roomUser.type = 'guest'
    roomUser.country = 'Earth';
  }
  let today = new Date();
  if (matchedUser && matchedUser.dob) {
    const dob = new Date(matchedUser.dob);
    const age = today.getFullYear() - dob.getFullYear();
    roomUser.dob = age;
  } else {
    roomUser.dob = '18+'
  }

  store.addUser(roomUser);
  store.increaseRoomUserCount(room);
  store.storeNewUser(roomUser);

  let joinUserDetail = {
    type: 'join',
    userName: name,
    roomName: room,
    message: `(${host}) has joined `,
  }
  store.addJoinUserMessage(joinUserDetail);
});

event.on(replies.RPL_KILL, (data) => {
  const store = useMainStore();
  const splittedData = data.split(' ');
  const killerName = splittedData[0].split("!")[0].substring(1);
  let killedName = splittedData[2];
  let message = splittedData.slice(3, splittedData.length).join(' ').substring(1).match(/\(([^()]*\([^()]*\))\)/)[1];

  if(killedName === store.userName) {
    let killedMessage = "Killed: " + message;
    store.showKilledModal(killedMessage);
    store.clearRoomList()
    store.setActiveRoom('');
    store.clearMessageHistory();
  } else {
    store.removeUserFromAllRooms(killedName);
  }
});

event.on(replies.RPL_KICK, (data) => {
  const store = useMainStore();
  const splittedData = data.split(' ');
  const kickerName = splittedData[0].split("!")[0].substring(1);
  let room = splittedData[2];
  let kickedName = splittedData[3];
  let message = splittedData.slice(4, splittedData.length).join(' ').substring(1);

  if(kickedName === store.userName) {
    let kickedMessage = 'You been kicked from ' + room + ' by ' + kickerName + ' (' + message + ')';
    store.showKickedModal(kickedMessage);
    store.removeRoomListByName(room)
    if(store.activeRoomList[0]) {
      join(store.activeRoomList[0].name);
    } else {
      store.showRoomList();
      store.setActiveRoom('');
    }
    store.clearRoomMessages(room);
  } else {
    store.removeRoomUser(kickedName, room);
  }
});

event.on(replies.RPL_PRIVMSG, (data) => {
  const store = useMainStore();
  const splittedData = data.split(' ');
  let type = 'message';

  let message = splittedData;
  let identifier = ":IDENTIFIER:";
  let startIndex = splittedData[3].startsWith(identifier) ? 4 : 3;

  if(splittedData[3] === ':\u0001ACTION') {
    startIndex = 4;
    type = 'action';
  }

  message = message.slice(startIndex, message.length)
  let messageString = message.join(" ");

  let controlChar = String.fromCharCode(1); 
  messageString = messageString.replace(new RegExp(controlChar + '$'), '');

  messageString = messageString.replace(/^:/, "");
  let userName = data.split("!");
  userName.splice(1, userName.length - 1);
  let userNameString = userName.join(" ")
  userNameString = userNameString.replace(/^:/, "");

  let room = splittedData[2];
  if(!room.startsWith('#')) {
    room = splittedData[0].split("!")[0].substring(1);
  }
  let senderName = userNameString;
  let roomName = room;
  let today = new Date();
  let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  let recipient = (startIndex == 4 ? splittedData[3].substring(identifier.length) : ''); // get recipient username

  if(checkNameSameWithModerator(recipient, store.userName) || messageString.includes(store.userName) || checkNameSameWithModerator(splittedData[2], store.userName)) {
    if (document.visibilityState === 'hidden') {
      const focusRoom = store.userName === room ? senderName : room;
      showNotification(`Hey ${store.userName}! You are mentioned at ${focusRoom}`);
    }
  }

  let style = (type === 'action' ? 'font-bold italic' : '');

  store.socketMessage.forEach(message => {
    if (message.message == messageString) {
      style = message.css;
    }
  });

  let chatMessage = {
    userName: senderName,
    message: messageString,
    gif: '',
    room: roomName,
    style: style,
    time: time,
    type: type,
    recipient: recipient
  };

  const urlRegex = /^(?:https?|ftp):\/\/[^\s]+/;

  if (chatMessage.message.startsWith("blob:")) {
    chatMessage.type = 'gif';
    chatMessage.gif = messageString;
    chatMessage.message = '';
  }

  if (urlRegex.test(messageString)) {
    chatMessage.type = 'gif';
    chatMessage.gif = messageString;
    chatMessage.message = '';
  }
  
  if (roomName.split("")[0] != "#" && store.enablePrivateMessage) {
    roomName = userNameString;

    var gender;

    const matchedUser = store.allUserList.find(user => user.userName === roomName);
    if (matchedUser) {
      if (matchedUser.userName == roomName) {
        gender = matchedUser.gender
      }
      else {
        gender = 'others'
      }
    }

    let user = {
      name: roomName,
      count: 1,
      topic: "Private message",
      roomType: "user",
      style: style,
      gender: gender,
    };

    let roomExits = store.activeRoomList.find(room => room.name == roomName);
    if (!roomExits) {
      store.storeActiveRoomList(user);
    }

    if (!store.blockMaleUser && user.gender == 'male') {
      chatMessage = {
        userName: senderName,
        message: messageString,
        gif: '',
        room: roomName,
        style: style,
        time: time,
        type: type,
        recipient: recipient
      }
    }

    if (!store.blockFemaleUser && user.gender == 'female') {
      chatMessage = {
        userName: senderName,
        message: messageString,
        gif: '',
        room: roomName,
        style: style,
        time: time,
        type: type,
        recipient: recipient
      }
    }

    if (!store.blockOtherUser && user.gender == 'others') {
      chatMessage = {
        userName: senderName,
        message: messageString,
        gif: '',
        room: roomName,
        style: style,
        time: time,
        type: type,
        recipient: recipient
      }
    }
  }

  if (store.notificationOnTab) {
    const findRoom = store.activeRoomList.find(room => room.name === chatMessage.room);
    if (findRoom && store.activeRoom != findRoom.name) {
      const tabNotification = {
        notification: 'not...',
        room: findRoom.name,
      }
      store.storeTabNotification(tabNotification);
    }
  }

  store.addMessage(chatMessage);
  
  if (store.notificationSound) {
    var notification = new Audio("/audio/notification.wav")
    notification.play()
  }
  if (store.vibrationOnMessage) {
    if ("vibrate" in navigator) {
      // The Vibration API is supported
      navigator.vibrate(400);
    } else {
      // The Vibration API is not supported
      console.log("Vibration API is not supported on this device or browser.");
    }
  }
});

event.on(replies.ERR_NOSUCHNICK, (data) => {
  let spliceData = data.split(" ");
  const room = spliceData[3];

  const offlineMessage = {
    message: 'This person is not online',
    room: room,
    style: 'bg-gray-300 text-center font-bold italic w-full',
    type: 'Offline user'
  }

  store.addMessage(offlineMessage);
});

event.on(replies.RPL_NOTICE, (data) => {
  const store = useMainStore();
  let message = data.split(" ");
  message.splice(0, 3);

  let messageString = message.join(" ");
  messageString = messageString.replace(/^:/, "");

  let firstPart = data.split(" ")[0];
  let userName = firstPart.split("!");
  userName.splice(1, 1);
  let userNameString = userName.join(" ")
  userNameString = userNameString.replace(/^:/, "");

  let today = new Date();
  let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

  let style = "italic font-bold";

  let chatMessage = {
    direct: 'from',
    userName: userNameString,
    message: messageString,
    room: store.activeRoom,
    time: time,
    style: style,
    type: 'whisper'
  }
  if (!store.blockWhisper) {
    store.addMessage(chatMessage);
  }
});

event.on(replies.RPL_PART, (data) => {
  const store = useMainStore();

  // Split the text into parts to extract the necessary information
  const parts = data.split(" ");
  const usernameAndHost = parts[0].substring(1); // Removes the leading ":"
  const room = parts[2].substring(1); // Removes the leading ":"
  const [name, host] = usernameAndHost.split("!");

  let quitUserDetail = {
    type: 'part',
    userName: name,
    roomName: room,
    message: `(${host}) has left `,
  }
  store.decreaseRoomUserCount(room);
  store.removeRoomUser(name, room);
  store.addQuitUserMessage(quitUserDetail);
});

event.on(replies.RPL_MODE, (data) => {
  const room = data.split(' ')[2];
  
  send(`NAMES ${room}`);
});