/* eslint-disable no-control-regex */
/* eslint-disable no-unused-vars */
import { store } from "@/store";
import event from "./event";
import replies from "./replies";
import { useMainStore } from "@/mainStore";

export function log(...params) {
    console.log(...params);
}

export function login(nick, pass) {
    store.commit("setNick", nick);

    if (store.getters.irc) {
        console.log("Closing existing connection")
        store.getters.irc.close();
    }

    connect(() => {
        send(`NICK ${nick}`);
        send(`PASS ${pass}`)
        send(`USER FreeChat 0 * :123FreeChat.com`);
    });
}

export function join(room) {
    const store = useMainStore();
    send(`JOIN ${room}`);
    store.setActiveRoom(room);
    
    if (store.notificationSound) {
        const joinAudio = new Audio("/audio/join.wav");
        joinAudio.play();
    }
}

export function quit(room) {
    const store = useMainStore();
    store.clearRoomMessages(room);
    send(`PART ${room}`);
    
    if (store.notificationSound) {
        const quitAudio = new Audio("/audio/quit.wav");
        quitAudio.play();
    }
}

export function logout(userName) {
    const store = useMainStore();
    send(`QUIT ${userName}`)
    store.hideMessageRoom()
    store.hideMenuList()
    store.enableButton()
    store.messageHistory = {}
    store.activeRoomList = []
    store.socketUserList = []
    store.showLoginModal()
    store.socket.disconnect();
}

export function sendMessage(message, room) {
    const store = useMainStore();
    let today = new Date();
    let time = today.getHours() + ":" + today.getMinutes();
    let cmd = `PRIVMSG ${room} ${store.recipient.length > 0 ? `IDENTIFIER:${store.recipient}` : ''} ${message}`
    send(cmd);

    let chatMessage = {
        userName: store.userName,
        message: message,
        gif: '',
        room: room,
        time: time,
        type: 'message',
        recipient: store.recipient,
        style: store.BackgroundColor + " " + store.textColor + " " + store.messageStyleBold + " " + store.messageStyleUnderline,
    };

    const urlRegex = /^(?:https?|ftp):\/\/[^\s]+/;

    if (urlRegex.test(chatMessage.message)) {
        chatMessage.type = 'gif';
        chatMessage.gif = chatMessage.message;
        chatMessage.message = '';
    }

    if (chatMessage.message.startsWith("blob:")) {
        chatMessage.type = 'gif';
        chatMessage.gif = message;
        chatMessage.message = '';
    }
    store.addMessage(chatMessage);
}

export function sendAction(message, room, user) {
    const store = useMainStore();
    let today = new Date();
    let time = today.getHours() + ":" + today.getMinutes();
    let cmd = `PRIVMSG ${room} :\x01ACTION ${message}\x01`;
    send(cmd);

    let chatMessage = {
        userName: user,
        message: message,
        gif: '',
        room: store.activeRoom,
        style: 'font-bold italic',
        time: time,
        type: 'action',
        recipient: ''
    };
    store.addMessage(chatMessage);
}

export function sendWhisper(message, user) {
    const store = useMainStore();
    let today = new Date();
    let time = today.getHours() + ":" + today.getMinutes();
    send(`NOTICE ${user} :${message} `);
    let chatMessage = {
        direct: "to",
        userName: user,
        message: message,
        room: store.activeRoom,
        time: time,
        style: "font-bold italic",
        type: 'whisper',
    };
    store.addMessage(chatMessage);
}

export function sendReport(message) {
    send(`PRIVMSG Bristol ${message}`)
}

export function identify(password) {
    send(`IDENTIFY ${store.getters.nick} ${password}`);
}

export function send(command) {
    store.getters.irc.send(command);
}

function connect(callback) {
    let irc;
    log("Creating IRC connection over WebSocket");
    irc = new WebSocket("wss://chat.123freechat.com:8081");

    irc.onopen = () => { callback(); onopen(); };
    irc.onmessage = onmessage;
    irc.onclose = onclose;

    store.commit("setIrc", irc);
}

function onopen() {
    log("IRC connection established");
}

function onmessage(e) {
    log("IN:", e.data);
    let message = e.data;
    let eventID = getEventID(message);

    event.emit(eventID, message);
}

function getEventID(message) {
    let messageParts = message.split(" ");
    let command = messageParts[1];
    let sender = messageParts[0].split("!")[0];

    if (sender == ":NickServ" && message.indexOf("/msg NickServ IDENTIFY") > 0) {
        command = replies.RPL_PASSWORDNEEDED;
    }
    else if (sender == ":NickServ" && message.indexOf("Invalid password for") > 0) {
        command = replies.RPL_INVALIDPASSWORD;
    }
    else if (sender == ":NickServ" && message.indexOf("You are now identified") > 0) {
        command = replies.RPL_LOGGEDIN;
    }
    else if (message.indexOf("PING") === 0) {
        command = replies.RPL_PING
    }

    log("Event: ", command);

    return command;
}

function onclose() {
    log("IRC connection closed");
}
